@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Overlay:not(.Overlay_isStatic) {
    .CmsBlock-Wrapper_type_minicartHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        background-color: $color-primary-85;
        color: $white;
        font-size: 16px;
        margin: 0;

        p {
            color: $white;
            font-size: 16px;
            margin: 0;
        }
    }

    .CartOverlay {
        &-Additional {
            background-color: $color-neutral-20;
        }

        &-Empty {
            padding: 32px 72px;
            color: $default-primary-base-color;
        }

        &-OutOfStockProductsWarning {
            background-color: $color-other-8;
        }

        &-Total {
            font-weight: 400;
            font-size: 20px;
            padding-top: 24px;
            padding-bottom: 32px;
            background: none;

            dd {
                font-size: 24px;
                font-weight: 500;
                color: $default-primary-base-color;
            }

            dt {
                color: $default-primary-base-color;
            }
        }

        &-Actions {
            padding-bottom: 24px;
        }

        &-TaxInfo {
            font-size: 14px;
            font-weight: 400;
            color: $color-neutral-50;
            margin-top: 4px;
        }

        &-CheckoutButton {
            display: flex;
            justify-content: baseline;
            align-items: center;
        }

        &-ContentWrapper {
            border: 0;

            .CartItem {
                &-Heading a {
                    font-size: 13px;
                }

                .Image img {
                    width: 110px;
                    height: 100%;
                    object-fit: contain;
                }

                &-Button svg {
                    font-size: 18px;
                    width: 16px;
                    height: 27px;
                }

                &-ProductActions {
                    margin-bottom: 10px;
                }

                &-Wrapper {
                    grid-gap: 13px;
                }

                &-ProductInfo {
                    padding-top: 10px;
                }
            }
        }
    }

    &.CartOverlay {
        .PriceToFreeDelivery {
            text-align: center;
            padding-bottom: 24px;
        }
    }

    &::before {
        display: none;
    }
}

@include mobile {
    .Overlay:not(.Overlay_isStatic) {
        padding-bottom: 0;

        .CartOverlay {
            &-ContentWrapper {
                display: flex;
                flex-direction: column;
                height: calc(100% - 50px);
                overflow-y: hidden;
            }

            &-Items {
                max-height: 70vh;
            }

            &-Additional {
                margin-top: auto;
            }
        }
    }
}
