@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartCoupon {
    .Field {
        margin-bottom: 0;
    }

    form.Form {
        margin-top: 0;
    }

    &-Notification {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 40px;
        padding: 10px;
        margin-bottom: 16px;
        font-size: 15px;
        color: $white;

        &_isApplied {
            background-color: $color-other-4;
        }

        &_isIncorrect {
            background-color: $color-other-3;
        }
    }
}
