@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartPage {
    overflow-x: clip;

    &-Heading {
        margin-bottom: 16px;
    }

    &-RewardsPointsForCart {
        display: flex;
        gap: 16px;
        margin: 16px 0;

        & p {
            display: flex;
            gap: 5px;
            color: $dark-transparent;
            align-items: center;

            & span {
                font-weight: 500;
                color: $color-neutral-90;
            }
        }
    }

    &-Summary {
        margin-top: 55px;
    }

    .CmsBlock-Wrapper {
        &_type_cart_cms, &_type_cart_cms p {
            color: $color-neutral-75;
            font-size: 16px;
            margin-bottom: 0;
        }

        &_type_cart_cms {
            margin-top: 24px;
        }
    }

    &-Floating {
        margin-top: 86px;
    }

    &-ProductSliderFirst {
        margin: 84px 0 0 0;
        overflow: hidden;
        padding: 0 0 125px 0;
    }
}

@include mobile {
    .CartPage {
        &-Static {
            margin-right: 0;
        }

        &-Items {
            max-height: 1010px;
            overflow: hidden;

            &_isAllItemsShown {
                max-height: 100%;
            }
        }

        &-Discount {
            display: none;
        }
    }
}

@include mobileAndTablet {
    .CartPage {
        padding-bottom: 16px;

        &-ProductSliderFirst {
            margin-top: 0;
        }
    }
}

@include desktopAndAbove {
    .CartPage {
        &-Overflow {
            display: flex;
            flex-direction: row;
        }

        &-Floating {
            max-width: 326px;
        }

        &-Static {
            margin-right: 24px;
            flex: 1;
        }

        &-ProductSliderFirst {
            h2 {
                font-size: 30px;
                font-weight: 500;
            }
        }
    }
}
