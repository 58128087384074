@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartItem {
    &:last-child {
        .CartItem {
            &-Wrapper {
                &_isSummary {
                    border-bottom: none;
                }
            }
        }
    }

    &-LargeSizeDelivery {
        display: flex;
        margin-top: 14px;
        align-items: center;

        svg {
            margin-right: 8px;
        }

        span {
            font-size: 14px;
            color: $color-other-8;
        }
    }

    &-Wrapper {
        &_isCart {
            margin-bottom: 33px;
        }

        &_isSummary {
            grid-template-columns: 72px auto;
            align-items: start;
            border-bottom: 1px solid $color-neutral-35;

            .CartItem {
                &-Picture {
                    width: 100%;
                    height: 72px;
                }

                &-ProductActions {
                    justify-content: space-between;
                    align-items: start;
                }

                &-ProductInfo {
                    padding-top: 0;
                }

                &-Heading {
                    font-size: 13px;
                    line-height: 19px;
                    margin-bottom: 8px;
                }

                &-Quantity {
                    font-size: 13px;
                }

                &-Price {
                    &.ProductPrice {
                        padding: 0;
                        margin: 0;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: initial;
                    }

                    &Total {
                        flex-direction: row-reverse;
                        gap: 8px;

                        .ProductPrice {
                            &-HighPrice {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-PriceTotal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-Picture {
        width: 200px;
        height: 150px;

        img {
            object-fit: contain;
        }
    }

    .CartItem-Price {
        padding-top: 16px;
        font-size: 16px;
    }

    &-TableHeader {
        padding-bottom: 16px;
    }

    &-QuantityWrapper {
        padding-top: 17px;
    }

    &-Button {
        font-size: 14px;
        gap: 8px;
        color: $default-primary-base-color;

        svg {
            width: 16px;
            height: 24px;
        }
    }

    &-ShippingTime {
        svg {
            margin-right: 8px;
        }
    }

    &-DescriptionWrapper {
        .CartItem-Title {
            padding-right: 0;
        }
    }

    &-Options {
        margin-bottom: 15px;
    }

    &-Option {
        div {
            white-space: normal;
        }
    }
}

@include desktopAndAbove {
    .CartItem {
        &-Wrapper_isCart {
            grid-template-columns: 1fr 91px 130px 100px;
        }
    }
}


@include mobile {
    .CartItem {
        &-DescriptionWrapper {
            flex-direction: row;
        }

        &-Heading {
            font-size: 12px;
        }

        &-ProductInfo {
            gap: 16px;
        }

        &-Actions {
            flex-direction: column-reverse;
            align-items: flex-end;
            justify-content: space-around;
        }

        &-ShippingTime {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }

        .ProductPrice.CartItem-Price {
            padding-top: 0;
            font-size: 14px;
        }

        &-QuantityWrapper {
            padding-top: 0;
        }
    }
}
