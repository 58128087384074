$white: #fff;
$black: #000000;
$default-primary-base-color: #000;
$default-primary-dark-color: #797979;
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-muli: 'Muli', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-alata: 'Alata', sans-serif;
$font-dm-serif-display: 'DM Serif Display', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-standard-size: 62.5%;

// Widths

$page-max-width: 1416px;
$page-max-width-pdp: 1464px;
$page-max-width-cms: 1464px;
$page-max-width-cart: 1464px;
$page-padding-left-mobile: 16px;
$page-padding-right-mobile: 16px;
$page-padding-left: 15px;
$page-padding-right: 15px;

// Colors

$color-neutral-90: #000;
$color-neutral-80: #484848;
$color-neutral-75: #5C5C5C;
$color-neutral-70: #797979;

$color-neutral-60: #8a8989;
$color-neutral-50: #a1a1a1;
$color-neutral-40: #d1d1d1;
$color-neutral-35: #E6E6E6;
$color-neutral-30: #e7e7e7;
$color-neutral-25: #F5F5F5;
$color-neutral-20: #f3f3f3;
$color-neutral-15: #f8f8f8;
$color-neutral-10: #f9f9f9;
$color-neutral-5: #fff;


$color-primary-95: #a8959a;
$color-primary-90: #3a5950;
$color-primary-85:#45062E;
$color-primary-80: #426b5f;
$color-primary-70: #a8959a;
$color-primary-60: #000;
$color-primary-50: #a8959a;
$color-primary-40: #8ac8b7;
$color-primary-30: #a7d6c9;
$color-primary-20: #bde0d6;
$color-primary-10: #cee8e0;
$color-primary-5: #daeee8;

$color-secondary-90: #982828;
$color-secondary-80: #ba2828;
$color-secondary-75: #C60C30;
$color-secondary-70: #dd2d2d;
$color-secondary-60: #eb4e4e;
$color-secondary-50: #ff5959;
$color-secondary-40: #ff9595;
$color-secondary-30: #ffafaf;
$color-secondary-20: #ffc3c3;
$color-secondary-10: #ffd2d2;
$color-secondary-5: #ffdddd;

$color-other-1: #82a2f6;
$color-other-2: #144fe8;
$color-other-3: #cea87e;
$color-other-4: #63B69E;
$color-other-5: #F8BB16;
$color-other-6: #19275B;
$color-other-7: #cfb53b;
$color-other-8: #7f055f;
$color-other-9: #3e8686;
$color-other-10: #191C1D;

$color-teriary-1: #a8959a;
$color-teriary-2: #adadad;
$color-teriary-3: #c8bcb1;
$color-teriary-4: #d1d1d1;
$color-teriary-5: #d6d6d6;
$color-teriary-6: #19275B;
$color-teriary-7: #858585;
$color-teriary-8: #d9d9d9;
$color-teriary-9: #f8f8f8;

$color-primary-final: #A8959A;


$loyalty-color-primary: #3c0d30;
$loyalty-content-gradient: linear-gradient(0deg, var(--footer-background), $white 50%);
$loyalty-step-gradient: 0px 10px 32px 0px rgba($color-neutral-40, 0.5);

$radial-gradient-1: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F2F4FB 100%);

$white-transparent: rgba(255, 255, 255, 0.4);
$gray-transparent: rgba(168, 149, 154, 0.1);
$dark-transparent: rgba(0, 0, 0, 0.6);
$dark-half-transparent: rgba(0, 0, 0, 0.5);
$dark-light-transparent: rgba(0, 0, 0, 0.3);
$dark-transparent-64: #000000a3;

$orlen-color-grey: #555;
$orlen-color-blue: blue;
$orlen-color-green: green;

$lp-color-1: #1a1a1a;
$lp-color-2: #fff;
$lp-color-3: #f5f5f5;
$lp-color-4: #a2a2a2;
$lp-color-5: #ffc80b;
$lp-color-6: #000;
$lp-color-7: #D6D6D6;
