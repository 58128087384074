@mixin page-container {
    max-width: $page-max-width;
    padding-left: $page-padding-left-mobile;
    padding-right: $page-padding-right-mobile;
    margin-left: auto;
    margin-right: auto;

    @include desktopAndAbove {
        padding-left: $page-padding-left;
        padding-right: $page-padding-right;
    }
}

@mixin default-transition($properties: all, $speed: .3s, $type: ease-in-out) {
    transition: $properties $speed $type;
}

@mixin scrollbar($scrollbar-thumb-color: $color-primary-50, $scrollbar-rail-color: $color-neutral-20 ) {
    $scrollbar-base-color: $color-primary-50;

    $scrollbar-width: 5px;
    $scrollbar-width-moz: thin;
    $scrollbar-border-radius: 0;

    // IE

    .TA {
        scrollbar-base-color: $scrollbar-rail-color;
        scrollbar-face-color: $scrollbar-thumb-color;
    }

    // Chrome & Safari (WebKit)

    &::-webkit-scrollbar {
        background: $scrollbar-rail-color;
        width: $scrollbar-width;
        height: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-rail-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
        border-radius: $scrollbar-border-radius;
    }

    // Firefox (Gecko)
    scrollbar-color: $scrollbar-thumb-color $scrollbar-rail-color;
    scrollbar-width: $scrollbar-width-moz;
}
